import axios from "axios";

// TODO: set auth tokens in env vars on the server
const authUser = "svg-editor";
const authPW = "g8yu DbsK Mfp0 Ws7j UjCX 7rUT";

export function updateAcademyUser (svg, userID, blockID, url) {
    const endpoint = `https://academy.ed-fi.org/wp-json/wp/v2/users/${userID}`;

    axios.post(endpoint, {
        "acf":
            {
                "user_whiteboards": [
                    {
                        "block_id": blockID,
                        "image_svg": svg
                    }
                ]
            }
        }, {
        auth: {
            username: authUser,
            password: authPW
          }
        })
        .then(function (response) {
            console.log(response);
            window.location = url;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function checkForExistingWhiteboard (academyUser) {
    const endpoint = `https://edfiacademy3.wpengine.com/wp-json/wp/v2/users/${academyUser}`;

    axios.get(endpoint, {
        auth: {
            username: authUser,
            password: authPW
        }
      }).then(resp => {
        console.log(resp.data.acf.user_whiteboards);
        return resp.data.acf.user_whiteboards
    });
}