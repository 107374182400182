/*
 * Save image to server and pass URL
 */
import { updateAcademyUser } from '../api';


const saveSVGToUser = (SVGmarkup, academyUser, block_id, returnURL) => {
  const svg = SVGmarkup;
  const user = academyUser;
  const block = block_id;
  const url = returnURL;

  updateAcademyUser(svg, user, block, url);
}


export {
  saveSVGToUser
}