
 const redirectPage = () => {
    let tID = setTimeout( function () {
        window.location.href = "https://academy.ed-fi.org/";
        window.clearTimeout(tID); // clear time out.
    }, 4000);
  }


  export {
    redirectPage
  }